body, html, #root,.App,.main-body,.main-login {
    height: 100%;
    margin: 0;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.main-login{
    background-image: url('../public/img/Logo.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-bottom-30{
    bottom:  30px;
}

.m-bottom-50{
    bottom:  50px;
}

.font-size-logo{
    font-size: 40px;
}
.font-size-menu{
    font-size: 12px !important;
}

.font-size-20{
    font-size: 20px !important;
}

.font-size-14{
    font-size: 14px !important;
}

.toast-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050; /* Ensure it is above other elements */
  }

  .text-left{
    text-align: left !important;
  }

  .loader {
    text-align: center;
    padding: 10px;
  }

.loadding{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999;
    background: #e7e7e7;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    justify-content: center;
    align-items: center;
}
.text-dark-red{
    color: #8B0000 !important;
    font-weight: 500;
}